@import "./shared/Loader.css";
@import "./shared/gradiant-button.css";
@import "./shared/nav.css";
@import "./shared/custom.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: DroidSans;
    src: url('./fonts/DroidSans.ttf');
}
html,#root{
    height: 100%;
    background-color: #727272;
}
body {
    color: #333333;
    margin: 0;
    font-family: DroidSans,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.grey{
    background-color: #F3F4F5;
}
.grey-777{
    background-color: #727272;
}
.dark-grey{
    background-color: #D1D2D3;
}
.green{
    background-color: #009345;
}
.survey-text p{
    font-size: 1.3rem;
    line-height: 1.5rem;
    margin-bottom: 0.75rem;
}