.button-gradient,.button-gradient-disabled {
    position: relative;
    display: inline-block;
    background-color: #6ebb40; /*for compatibility with older browsers*/
    background-image: radial-gradient(rgba(70, 69, 69, 0.8), rgba(37, 36, 36, 0.99), rgb(54, 54, 54));
    /* text styles */
    text-decoration: none;
    color: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 4px -2px #333;
    font-weight: bold;
}
.button-gradient:after,.button-gradient-disabled:after{
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: calc(100% - 4px);
    height: 50%;
    background: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.2));
    border-radius: 8px 8px 0 0;
}
.button-gradient:hover,.enabled,.checked{
    background-image: radial-gradient( rgb(141, 192, 65),rgb(136, 204, 88),  rgba(5, 155, 12, 0.84), rgba(1, 117, 58, 0.99));
}

.button-gradient-disabled{
    cursor: not-allowed;
}
.button-default{
    min-width: 10rem;
    padding: 10px 25px;
    font-size:25px ;
}

.button-radio{
    max-height: 3rem;
    min-height: 3rem;
}