.nav-path {
    height: 45px;
}

.path-container {
    position: absolute;


}
.path-container li{
    float: left;

    list-style: none;
    height: 45px;
    padding:0px 30px 0px 50px;
    font-size: 16px;
    font-weight: bold;
    line-height: 45px;


}
.corner{
    position: absolute;
    top:45px;
    width: 24px;
    height: 16px;
    background-image: url(../../assets/corner.png);
    background-position: 0 0;
}
.flip-corner{
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.active-path{
    background-color: #009345;
    color:#fff;
}
.path-line{
    background-color: #D1D2D3;
    position: relative;
}